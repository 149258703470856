var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"data-table",class:_vm.canBeSelected ? 'data-table--selectable' : '',attrs:{"elevation":"4"}},[_c('v-data-table',{staticClass:"elevation-0 custom-data-table",attrs:{"calculate-widths":"","headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"search":_vm.searchTerm,"sort-by":_vm.sortBy.value,"sort-desc":_vm.sortDesc,"hide-default-footer":"","hide-default-header":"","mobile-breakpoint":"200","server-items-length":_vm.itemsTotal},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.user",fn:function(item){return [_c('user-item',{attrs:{"user":item.item.user}})]}},{key:"item.action",fn:function(item){return [_c('log-item',{attrs:{"action":item.item}})]}},{key:"item.date",fn:function(item){return [_c('v-tooltip',{attrs:{"open-delay":"150","close-delay":"150","offset-y":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"mb-0 text-right gray--text text--darken-1"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.moment(item.item.date).fromNow())+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.moment(item.item.date).format("YYYY-MM-DD HH:mm")))])])]}},(_vm.itemsLoading)?{key:"body",fn:function(){return [(_vm.$vuetify.breakpoint.lgAndDown)?_vm._l((5),function(item){return _c('logs-table-item-skeleton-mobile',{key:item})}):[_c('logs-table-sort-skeleton'),_c('logs-table-skeleton-item')]]},proxy:true}:null,{key:"no-data",fn:function(){return [(_vm.searchTerm)?_c('data-iterator-no-results-container',{attrs:{"searchTerm":_vm.searchTerm},on:{"clearAllFilters":function($event){return _vm.clearFilters()}}}):_vm._e()]},proxy:true},(_vm.$vuetify.breakpoint.width >= 1400)?{key:"header",fn:function(ref){
var isMobile = ref.isMobile;
var props = ref.props;
var on = ref.on;
return [(!_vm.itemsLoading)?_c('table-custom-sort',_vm._g({attrs:{"isMobile":isMobile,"props":props,"v-on":on}},_vm.$listeners)):_vm._e()]}}:null,(_vm.$vuetify.breakpoint.width < 1400)?{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"w-100"},[_c('div',{staticClass:"mobile-table-item"},[_c('div',{staticClass:"mobile-table-item__values"},[_c('div',{staticClass:"mobile-table-item__row"},[_c('div',{staticClass:"mobile-table-item__value"},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('user-item',{attrs:{"user":item.user}}),_c('v-tooltip',{staticClass:"ml-auto",attrs:{"open-delay":"150","close-delay":"150","offset-y":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"mb-0 text-right gray--text text--darken-1"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.moment(item.date).fromNow())+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.moment(item.date).format("YYYY-MM-DD HH:mm")))])])],1)])]),_c('div',{staticClass:"mobile-table-item__row"},[_c('div',{staticClass:"mobile-table-item__value"},[_c('log-item',{attrs:{"action":item}})],1)])])])])])]}}:null,{key:"footer",fn:function(){return [_c('div',[_c('data-iterator-footer',_vm._g({attrs:{"page":_vm.page,"numberOfPages":_vm.numberOfPages,"possibleItemsPerPage":_vm.possibleItemsPerPage,"listStyle":_vm.listStyle,"itemsPerPage":_vm.itemsPerPage,"perPageLabel":_vm.$t('table.footer.log')}},_vm.$listeners))],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }